import React from "react";

import {Workshop} from "../../typings/Workshop";

import "./WorkshopCollapsibleArea.css";

type WorkshopCollapsibleAreaProps = {
    workshop: Workshop;
};

const WorkshopCollapsibleArea = ({workshop}: WorkshopCollapsibleAreaProps) => (
    <div className="WorkshopCollapsibleArea">
        <h2>
            {workshop.displayName}
        </h2>
        <p>
            {workshop.description}
        </p>
    </div>
);

export default WorkshopCollapsibleArea;
