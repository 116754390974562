import React from "react";

import "./Button.css";

type ButtonProps = {
  href?: string;
  children: any;
  onClick?: () => void;
  disabled?: boolean;
};

const Button = ({ href, children, onClick, disabled }: ButtonProps) => {
  return href ? (
    <a className="Button" href={href}>
      {children}
    </a>
  ) : (
    <button
      disabled={disabled}
      className="Button"
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
